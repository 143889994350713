import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import Layout from "../components/layout"
import Seo from "../components/global/seo"
import {
  // LocationMarkerIcon,
  // PhoneIcon,
  MailIcon,
} from "@heroicons/react/outline"

export default function KontaktyPage() {
  const data = useStaticQuery(
    graphql`
      {
        file(relativePath: { eq: "toa-heftiba-_UIVmIBB3JU-unsplash.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              formats: [AVIF, WEBP, JPG]
              width: 800
              placeholder: BLURRED
              quality: 85
            )
          }
        }
      }
    `
  )
  const bgImage = getImage(data.file.childImageSharp.gatsbyImageData)
  const gradientBg = [
    bgImage,
    `linear-gradient(to right, rgb(253 200 48 / 0.2), rgb(243 115 53 / 0.2))`,
  ].reverse()

  return (
    <Layout>
      <Seo
        title="О компанії Taho.org.ua. Контакти"
        description="Дізнатися більше про компанію Taho.org.ua. Контакти."
      />
      <BgImage image={gradientBg}>
        <main className="relative flex items-top justify-center min-h-screen sm:items-center sm:pt-0">
          <div className="max-w-6xl mx-auto sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <div className="grid grid-cols-1 md:grid-cols-2">
                <div className="p-6 bg-gray-100 sm:rounded-lg">
                  <h1 className="text-4xl sm:text-5xl text-gray-800 font-extrabold tracking-tight">
                    Зв'яжіться з нами
                  </h1>
                  <p className="text-normal text-base text-gray-600 my-6">
                    Якщо бажаєте додати нову компанію в каталог, виправити
                    інформацію, або маєте пропозиції та запитання по роботі
                    сайту, напишіть нам на електронну пошту, або відправте
                    повідомлення формою зворотного зв'язку.
                  </p>

                  {/* <div className="flex items-center mt-8 text-gray-600 dark:text-gray-400">
                  <LocationMarkerIcon className="w-8 h-auto text-yellow-600" />
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    Україна, Київ
                  </div>
                </div> */}

                  {/* <div className="flex items-center mt-4 text-gray-600 dark:text-gray-400">
                  <PhoneIcon className="w-8 h-auto text-yellow-600" />
                  <div className="ml-4 text-md tracking-wide font-semibold w-40">
                    +44 1234567890
                  </div>
                </div> */}

                  <div className="flex items-center mt-2 text-gray-600 ">
                    <MailIcon className="w-8 h-auto text-yellow-600" />
                    <div className="ml-4 text-md tracking-wide font-semibold w-40">
                      hello@taho.org.ua
                    </div>
                  </div>
                </div>

                <form
                  className="p-6 flex flex-col justify-center"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  name="contactForm"
                  form-name="contactForm"
                  method="POST"
                  action="/thankyou/"
                  id="contactForm"
                >
                  <div className="flex flex-col">
                    <input
                      className="hidden"
                      type="hidden"
                      name="form-name"
                      value="contactForm"
                    />
                    <input className="hidden" type="text" name="bot-field" />
                    <label htmlFor="name" className="hidden">
                      Ваше Ім'я
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Ваше Ім'я"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white border border-gray-400 text-gray-800 focus:border-yellow-500 focus:outline-none"
                    />
                  </div>

                  <div className="flex flex-col mt-2">
                    <label htmlFor="email" className="hidden">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white border border-gray-400 text-gray-800 focus:border-yellow-500 focus:outline-none"
                    />
                  </div>

                  <div className="flex flex-col mt-2">
                    <label htmlFor="tel" className="hidden">
                      Номер телефону
                    </label>
                    <input
                      type="tel"
                      name="tel"
                      id="tel"
                      placeholder="Номер телефону"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white border border-gray-400 text-gray-800 focus:border-yellow-500 focus:outline-none"
                    />
                  </div>

                  <div className="flex flex-col mt-2">
                    <label htmlFor="tel" className="hidden">
                      Ваше повідомлення
                    </label>
                    <textarea
                      type="textarea"
                      rows={3}
                      name="message"
                      id="message"
                      placeholder="Напишіть ваше повідомлення"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white border border-gray-400 text-gray-800 focus:border-yellow-500 focus:outline-none"
                    />
                  </div>

                  <button
                    type="submit"
                    className="bg-yellow-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-yellow-500 transition ease-in-out duration-300"
                  >
                    Відправити повідомлення
                  </button>
                </form>
              </div>
            </div>
          </div>
        </main>
      </BgImage>
    </Layout>
  )
}
